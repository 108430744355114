export const getSpeed = () => Math.random() * 30

export const getXDirection = (row: number): number => {
  if (row === 0) return 1

  if (row === 1) return Math.random() < 0.5 ? -1 : 1

  if (row === 2) return -1

  return Math.random() < 0.5 ? -1 : 1
}

export const getYDirection = (column: number): number => {
  if (column === 0) return 1
  else if (column === 1) return Math.random() < 0.5 ? -1 : 1
  else if (column === 2) return -1

  return Math.random() < 0.5 ? -1 : 1
}
