import Phaser from 'phaser'

const phaserConfig = {
  type: Phaser.AUTO,
  parent: 'game',
  transparent: true,
  scale: {
    width: window.innerWidth,
    height: window.innerHeight - 110,
    mode: Phaser.Scale.FIT,
    autoCenter: Phaser.Scale.CENTER_BOTH,
  },
  physics: {
    default: 'matter',
    matter: {
      enableSleeping: false,
      gravity: {
        y: 0.1,
      },
      debug: false,
    },
  },
}

export default phaserConfig
