import {
  uniqueNamesGenerator,
  Config,
  adjectives,
} from 'unique-names-generator'

const customAnimals = [
  'Bear',
  'Cat',
  'Dog',
  'Elephant',
  'Giraffe',
  'Horse',
  'Lion',
  'Otter',
  'Owl',
  'Panda',
  'Sloth',
  'Snake',
  'Tiger',
  'Turtle',
]

const customConfig: Config = {
  dictionaries: [adjectives, customAnimals],
  separator: ' ',
}

const uniqueName = uniqueNamesGenerator(customConfig)

const randomName = () => uniqueName[0].toUpperCase() + uniqueName.slice(1)

export default randomName
