import { GameContext } from 'context/Game.context'
import React, { useContext } from 'react'
import styles from './GameOverOverlay.module.scss'

const GameOverOverlay = () => {
  const { count } = useContext(GameContext)

  return (
    <div className={styles['game-over-overlay']}>
      <div className={styles.text}>SCORE</div>
      <div className={styles.text}>
        <h1>{count}</h1>
      </div>
      <div className={styles['balloons-overlay']} />
    </div>
  )
}

export default GameOverOverlay
