import React, { useCallback, useContext, useEffect, useState } from 'react'
import GameOverOverlay from 'components/gameOverOverlay/GameOverOverlay'
import { pushHighscore } from 'services/Services'
import { GameContext } from 'context/Game.context'
import Button from 'components/buttons/Button'
import { useLeaderboard } from 'hooks/Hooks'
import Leaderboard from './leaderboard/Leaderboard'
import styles from './GameEndScreen.module.scss'
import Form from './forms/Form'

interface GameEndScreenProps {
  restartGame: () => void
}

const GameEndScreen = ({ restartGame }: GameEndScreenProps) => {
  const [showLeaderboard, setShowLeaderboard] = useState(false)
  const [showOverlay, setShowOverlay] = useState(true)
  const { rank, getRank } = useLeaderboard(
    localStorage.getItem('username') as string
  )
  const { setShouldEndGame, count, setCount } = useContext(GameContext)
  const [email, setEmail] = useState(localStorage.getItem('email'))
  const [bestScore] = useState(localStorage.getItem('bestScore'))
  const username = localStorage.getItem('username') as string

  const setHighestScore = useCallback(async () => {
    if (email) {
      localStorage.setItem('bestScore', count.toString())

      try {
        await pushHighscore(username, count, email)
        await getRank()
      } catch (e) {
        console.error(e)
      }
    }
  }, [username, count, email])

  useEffect(() => {
    if (email) localStorage.setItem('email', email)
  }, [email])

  useEffect(() => {
    if (!bestScore) {
      setHighestScore()
      return
    }

    if (count > parseInt(bestScore, 10)) {
      setHighestScore()
    }
  }, [bestScore, count, setHighestScore])

  useEffect(() => {
    setTimeout(() => {
      setShowOverlay(false)
    }, 4300)
  }, [])

  const retstart = () => {
    setShouldEndGame(false)
    restartGame()
    setCount(0)
  }

  if (showOverlay) return <GameOverOverlay />

  if (showLeaderboard)
    return (
      <Leaderboard
        close={() => {
          setShowLeaderboard(false)
        }}
      />
    )

  return (
    <div className={styles['game-end-screen']}>
      <div className={styles['background-wrapper']} />
      <div className={styles['game-end-screen--content']}>
        <div className={styles['score-wrapper']}>
          <div className={styles['your-score-wrapper']}> Your score</div> <br />{' '}
          <div className={styles['bestscore-wrapper']}>
            {bestScore ?? count}
          </div>{' '}
          <br />{' '}
          <div className={styles['rank-wrapper']}>
            {email &&
              (rank >= 0
                ? `You are #${rank + 1} on the leaderboard`
                : 'You did not make it on the leaderboard')}
          </div>
        </div>
        <div className={styles['button-wrapper']}>
          {!email && <Form onsubmit={setEmail} />}
          <Button
            type="button"
            buttonType="text"
            onClick={() => setShowLeaderboard((prev) => !prev)}
          >
            Show leaderboard
          </Button>
          <Button
            buttonType="contained"
            type="button"
            onClick={() => retstart()}
          >
            Play again
          </Button>
        </div>
      </div>
    </div>
  )
}

export default GameEndScreen
