import React from 'react'
import styles from './Avatar.module.scss'

interface AvatarProps {
  avatar: string
}

const Avatar = (props: AvatarProps) => {
  const { avatar } = props

  return !avatar ? null : (
    <div className={styles.avatar}>
      {avatar !== undefined && (
        <img src={`data:image/png;base64,${avatar}`} alt="" />
      )}
    </div>
  )
}

export default Avatar
