import React, { useState } from 'react'
import axios from 'axios'
import { pushEmail } from 'services/Services'
import styles from './Form.module.scss'

interface FormProps {
  onsubmit: (s: string) => void
}

const Form = ({ onsubmit }: FormProps) => {
  const [userEmail, setUserEmail] = useState('')
  const [inputFocused, setInputFocused] = useState(false)

  const handleSubmit = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && inputFocused) {
      event.preventDefault()
      localStorage.setItem('email', userEmail)
      onsubmit(userEmail)
    }
  }

  return (
    <div className={styles['form--form-wrapper']}>
      <div>Register your email to submit your score to the leaderboard</div>
      <input
        className={styles['form--input-wrapper']}
        placeholder="Enter your email address here"
        autoComplete="off"
        type="email"
        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
        value={userEmail}
        onChange={(event) => setUserEmail(event.target.value)}
        onKeyDown={handleSubmit}
        onFocus={() => setInputFocused((prev) => !prev)}
        onBlur={() => setInputFocused((prev) => !prev)}
      />
      <div className={styles['form--policy-text']}>
        Your email will only be used to contact you in case you win. We won’t
        spam you afterwards, we promise. It’s in our{' '}
        <a
          className={styles['form--a']}
          href="https://www2.deloitte.com/se/sv/footerlinks1/privacy-statement.html?icid=bottom_%3Ficid%3Dbottom"
          target="_blank"
          rel="noreferrer"
        >
          policy
        </a>
      </div>
    </div>
  )
}

export default Form
