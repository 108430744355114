import React, { useContext, useEffect, useRef, useState } from 'react'
import Leaderboard from 'components/gameEndScreen/leaderboard/Leaderboard'
import OlofTitle from 'assets/olof-adventure-title-grouped.png'
import { EventContext } from 'context/Event.context'
import Button from 'components/buttons/Button'
import Selector from 'components/selectors/Selector'
import styles from './Welcome.module.scss'
import videoBackground from '../../../assets/moon-phone_1 1.mp4'

interface WelcomeProps {
  onClick: () => void
}

const Welcome = ({ onClick }: WelcomeProps) => {
  const [startClassNames] = useState(styles.start)
  const [showLeaderboard, setShowLeaderboard] = useState(false)
  const { event: eventOption, setEvent: setEventOption } =
    useContext(EventContext)
  const videoRef = useRef<HTMLVideoElement>(null)

  const eventOptions: string[] = ['KTH', 'LTH', 'LiU', 'test', 'test2', 'test3']

  useEffect(() => {
    const video = videoRef.current

    if (!video) return

    const handleSuspend = () => {
      if (video.paused) {
        video.play()
      }
    }

    video.addEventListener('suspend', handleSuspend)

    // eslint-disable-next-line consistent-return
    return () => video.removeEventListener('suspend', handleSuspend)
  }, [videoRef])

  if (showLeaderboard)
    return <Leaderboard close={() => setShowLeaderboard(false)} />

  return (
    <div className={startClassNames}>
      <video muted loop id="video-bg" playsInline ref={videoRef}>
        <source src={videoBackground} type="video/mp4" />
      </video>
      <div className={styles['content-wrapper']}>
        <div className={styles['image-wrapper']}>
          <img
            className={styles['background']}
            src={OlofTitle}
            alt="Olof game title"
          />
        </div>
        <div className={styles['input-content']}>
          {/* <div className={styles['event-text']}>At which event are you?</div>
          <Selector
            options={eventOptions}
            onChange={(s: string) => setEventOption(s)}
            value={eventOption}
          /> */}
          <div className={styles['button-wrapper']}>
            <Button
              buttonType="text"
              type="button"
              onClick={() => setShowLeaderboard(true)}
            >
              View leaderboard
            </Button>
            <Button
              type="button"
              onClick={() => {
                onClick()
              }}
            >
              Start
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Welcome
