import React, { useEffect } from 'react'
import styles from './GameCountDown.module.scss'

interface GameCountDownProps {
  startGame: () => void
}

const GameCountDown = ({ startGame }: GameCountDownProps) => {
  useEffect(() => {
    setTimeout(() => {
      startGame()
    }, 3200)
  }, [])

  return (
    <div className={styles['countdown-wrapper']}>
      <div className={`${styles.countdown} ${styles['countdown-three']}`}>
        3
      </div>
      <div className={`${styles.countdown} ${styles['countdown-two']}`}>2</div>
      <div className={`${styles.countdown} ${styles['countdown-one']}`}>1</div>
    </div>
  )
}

export default GameCountDown
