import React, { useState } from 'react'
import Intro from './intro/Intro'
import styles from './Menu.module.scss'
import CloudAnimation from './cloudAnimation/CloudAnimation'
import Welcome from './welcome/Welcome'

interface MenuProps {
  closeMenu: () => void
}

const Menu = ({ closeMenu }: MenuProps) => {
  const [toggleCloudAnimation, setToggleCloudAnimation] = useState(true)
  const [toggleWelcome, setToggleWelcome] = useState(false)
  const [toggleUser, setToggleUser] = useState(false)
  const [toggleIntro, setToggleIntro] = useState(false)

  /* {toggleUser && <User onClick={() => setToggleIntro(true)} />} */

  return (
    <div className={styles.menu}>
      {toggleCloudAnimation && (
        <CloudAnimation
          onSlideOut={() => {
            setToggleWelcome(true)
            setToggleCloudAnimation(false)
          }}
          message={'Loading'}
        />
      )}
      {toggleWelcome && (
        <Welcome
          onClick={() => {
            setToggleIntro(true)
            setTimeout(() => {
              setToggleWelcome(false)
            }, 1600)
          }}
        />
      )}
      {toggleIntro && (
        <Intro
          closeMenu={() => {
            closeMenu()
            setTimeout(() => {
              setToggleUser(false)
            }, 1100)
          }}
        />
      )}
    </div>
  )
}

export default Menu
