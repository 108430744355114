// /* eslint-disable */
import axios from 'axios'

const fetchUrl =
  'https://72g3bzcptk.execute-api.eu-north-1.amazonaws.com/default/getLeaderboard'

const postUrl =
  'https://72g3bzcptk.execute-api.eu-north-1.amazonaws.com/default/updateLeaderboard'

const postUrlEmail =
  'https://72g3bzcptk.execute-api.eu-north-1.amazonaws.com/default/updateLeaderboard'

const generateUrl =
  process.env.NODE_ENV === 'development'
    ? 'https://72g3bzcptk.execute-api.eu-north-1.amazonaws.com/default/generateAvatar'
    : 'https://72g3bzcptk.execute-api.eu-north-1.amazonaws.com/default/generateAvatar'

export const pushEmail = (username: string, email: string) =>
  axios
    .post(postUrlEmail, {
      username,
      email,
    })
    .then((res) => res.data)
    .catch((e) => console.log(e))

export const fetchLeaderBoard = async () =>
  axios
    .get(fetchUrl)
    .then((res) => res.data)
    .catch((e) => console.error(e))

export const pushHighscore = async (
  username: string,
  score: number,
  email: string
) => {
  try {
    const response = await axios.post(postUrl, {
      username,
      score,
      email,
    })
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const generateAvatar = (username: string) => {
  const [adjective, subject] = username.split(' ')

  return axios
    .post(generateUrl, {
      prompt: `A comic book cover of a ${username} centerd on a black background`,
      subject: subject.toLowerCase(),
      adjective: adjective.toLowerCase(),
    })
    .then((res) => res.data)
    .catch((e) => console.error(e))
}
