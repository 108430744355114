
import React, { createContext, ReactNode, useState } from 'react'

interface EventContextProviderProps {
  children: ReactNode
}

interface EventContextProps {
  event: string
  setEvent: (args: string) => void
}

export const EventContext = createContext({} as EventContextProps)


export const EventContextProvider = ({ children }: EventContextProviderProps) => {
  const [event, setEvent] = useState('')

  return (
    <EventContext.Provider value={{ event, setEvent }}>
      {children}
    </EventContext.Provider>
  )
}