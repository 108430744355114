import { GameContext } from 'context/Game.context'
import React, { useContext } from 'react'
import './Counter.scss'

const Counter = () => {
  const { count } = useContext(GameContext)

  return <p className="counter">{count}</p>
}

export default Counter
